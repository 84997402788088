import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import AiDesignerImageBoxBackground from "../../assets/img/AiDesignerImageBoxBackground.png";
import SimplePromptingImage from "../../assets/img/SimplePromptingExampleImage.png";
import { Button } from "pyxis-ui-kit";
import { SideSelect } from "./components/SideSelect";
import { Switch } from 'antd';
import ModePopup from "./ModePopup";
import ProductPopup from './ProductPopup'
import Preset from "./PresetPopup";
import LoadingCard from "./components/LoadingCard";
import BackArrowAiDesigner from '../../assets/svg/BackArrowAiDesigner.svg';
import { createProject } from "../../store/actions";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { openToast } from "../../Toasts";
import { incrementFileUploaded, trackUploadFiles } from "../../utilities/services/mixpanel";
import axios from 'axios'
import { AuthState, RolesState } from "../../store/types";
import { saveAiDesignData } from '../../store/actions/createAiquireActions';
import PhotoCarousel from './PhotoCarousel'
import Carousel from './Carousel'
import './Carousel.scss';
//@ts-ignore
import prompts from './prompts.ts'
import { makeStyles } from "@material-ui/core";
import ErrorDialog from "./ErrorDialog";


const SimplePromptingCard = () => {
    const dispatch = useDispatch();
    const [activeStep, setActiveStep] = React.useState<number>(1);
    const [modePopup, setModePopup] = React.useState<boolean>(false);
    const [productPopup, setProductPopup] = React.useState<boolean>(false);
    const [PresetPopup, setPresetPopup] = React.useState<boolean>(false);
    const [product, setProduct] = React.useState<any>(null);
    const [selectedTemplate, setSelectedTemplate] = React.useState<any>(null);
    const [selectedImage, setSelectedImage] = React.useState<any>(null);
    const [preset, setPreset] = React.useState<any>(null);
    const [questionFormat, setQuestionFormat] = React.useState<boolean>(false);
    const [ageandNumber, setAgeandNumber] = React.useState<any>({ age: null, number: null });
    const { userData } = useSelector((state: { authentication: AuthState }) => (state.authentication));
    const props = useSelector((state: any) => ({
        templateSizes: state.create.templateSizes,
        popularSizes: state.create.popularTemplateSizes,
        productId: state.roles.selectedProductId,
        folder: state.folders.activeFolderIdCreate,
    }));
    const [loadingScreen, setLoadingScreen] = React.useState<boolean>(false);
    const [sideSelectData, setSideSelectData] = React.useState<any>({});
    const [aiResponse, setAIResponse] = React.useState<any>({});
    const [promptValue, setPromptValue] = React.useState<any>('');
    const history = useHistory();
    const [mandatoryWords, setMandatoryWords] = React.useState<any>([]);
    const [selectedImages, setSelectedImages] = React.useState<any>([])
    const [open, setOpen] = React.useState(false);
    const ngrok = process.env.REACT_APP_TEXT_IMAGE_CREATER_BASE_URL
    const selectedProductId = useSelector((state: { roles: RolesState }) => (state.roles.selectedProductId));
    const [requestId, setRequestId] = useState('')

    const getStatus = async (uuid) => {
        await axios.get(`${ngrok}/api/status/${uuid}`, {
            headers: {
                'ngrok-skip-browser-warning': 'true'
            }
        })
            .then((res) => {
                if (res.data.request.status == "COMPLETED") {
                    setAIResponse(res.data)
                    setActiveStep(3)
                    setLoadingScreen(false);
                } else {

                }
            }).catch((err) => {
                console.log(err)

            })
    }

    const nextHandler = async () => {

        setLoadingScreen(true);
        const { lastName, firstName, emailId } = userData;
        trackUploadFiles({
            name: `${firstName} ${lastName}`,
            email: emailId,
            workspace: "",
            numberOfFiles: 1,
            fileTypes: ["image"],
        });

        const postData: any = {
            title: 'My Project',
            type: 'image',
            product_id: props.productId,
            sizes: [{ name: "custom1", type: "custom", width: 1080, height: 1080 }],
            template: null,
            folder: null,
        }
        dispatch(createProject(postData, (res: any, isError: any) => {
            if (isError) openToast('error', 'project creation failed');
            if (!isError) {
                incrementFileUploaded();
                const aiData = { ...aiResponse, images: selectedImages, requestId: requestId }
                dispatch(saveAiDesignData({
                    aiData: aiData,
                    selectedImage: selectedImage,
                    selectedTemplate: selectedTemplate,
                }))
                return history.push(`/cocreate/editor/${res.data.id}?type=aidesigner`);
            }
        }))

    }

    const checkState = async (exportId: any) => {
        const response = await axios.get(`${ngrok}/api/status/${exportId}`, {
            headers: {
                //   header1: value1,
                'ngrok-skip-browser-warning': 'true'
            }
        });
        return response.data.request.status;
    }

    const [presetOptions, setPresetOptions] = useState([])
    const [promptPopup, setPromptPopup] = useState(false)
    const [ageOptions, setAgeOptions] = useState([])
    let data = [{ title: 'Model (s)', items: product ? Object.keys(prompts[product])?.map((item) => ({ label: item })) : [] }]


    useEffect(() => {
        try {

            setPromptValue([])
            const genders = prompts[product]
            const age = genders[sideSelectData["Model (s)"]]
            const num = ageandNumber["number"]
            const prompts_arr = age[num]
            setPresetOptions(prompts_arr)
            setAgeOptions(Object.keys(age))

        } catch (err) {
            console.log(err)
        }
    }, [sideSelectData, ageandNumber, product])

    const finished = function (id) {
        return new Promise<void>((resolve, reject) => {
            let secondsPassed = 0;
            const checker = setInterval(async () => {
                let state = await checkState(id);
                if (state == 'PROCESSING' || state == 'PENDING') {
                    if (secondsPassed >= 4800) {
                        setLoadingScreen(false);
                        localStorage.setItem('aidesigner_prompts_error', null)
                        localStorage.setItem('aidesigner_prompts_error', "NOT RESPONDING")
                        setOpen(true)
                        clearInterval(checker);
                    } else {
                        secondsPassed += 10; // Increment by 10 minutes (interval is 10000 ms)
                    }
                } else if (state == 'FAILED') {
                    setLoadingScreen(false);
                    localStorage.setItem('aidesigner_prompts_error', null)
                    localStorage.setItem('aidesigner_prompts_error', "FAILED")
                    setOpen(true)
                    clearInterval(checker);
                    resolve();
                } else {
                    clearInterval(checker);
                    resolve();
                }
            }, 10000);
        });
    }

    const handleGenerate = async () => {
        setLoadingScreen(true);

        console.log(userData)


        await axios.post(`${ngrok}/api/generate-image/simple-prompt`,
            {
                "input_text": promptValue.value,
                "user_id": userData.userId,
                "workspace_id": selectedProductId,
                "product_type": product,
                "camera_settings": "Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM lens, f/1.2, ISO 4000, capturing a sense of joy and celebration --ar 16:9",
                "mandatory_words": mandatoryWords,
                "question_format": questionFormat
            }).then(async (res) => {
                setRequestId(res.data.uuid)
                await finished(res.data.uuid).then(() => {
                    getStatus(res.data.uuid);
                    setRequestId(res.data.uuid)
                }).catch((err) => {
                    console.log(err)
                })
            }).catch((err) => {
                const error = err?.response.data.error;

                const timeString = error.match(/after (.+?) GMT/)[1];
                localStorage.setItem('aidesigner_prompts_error', null)
                localStorage.setItem('aidesigner_prompts_error', timeString)

                setOpen(true)
                setLoadingScreen(false);
            })



        // setAIResponse(mock_data)
        // setLoadingScreen(false)
        // setActiveStep(3)

    }

    if (loadingScreen)
        return (
            <LoadingCard />
        )

    if (activeStep == 3)
        return (
            <div className="image-card">
                <div className="back-btn">
                    <img src={BackArrowAiDesigner} onClick={() => setActiveStep(1)} />
                </div>
                <div className="header">
                    <div className="title">Choose an image</div>
                    <div className="desc">Click on the image that resonates most with your product/ad</div>
                </div>
                <div className="content">
                    <PhotoCarousel requestId={requestId} images={aiResponse.request.imageResponse.imageUrls} setSelectedImage={setSelectedImage} setSelectedImages={setSelectedImages} selectedImages={selectedImages} />
                </div>
                <div style={{ display: 'flex', alignSelf: 'flex-end', gap: '10px' }}>
                    <Button btnsize="md"
                        style={{ alignSelf: 'flex-end' }}
                        className="primary submit refresh-btn"
                        onClick={handleGenerate}>
                        Refresh
                    </Button>
                    <Button btnsize="md"
                        style={{ alignSelf: 'flex-end' }}
                        type="primary"
                        className="primary submit"
                        onClick={() => setActiveStep(4)}
                        disabled={selectedImage == null}>
                        Next
                    </Button>
                </div>
            </div>
        )

    if (activeStep == 4)
        return (
            <div className="image-card">
                <div className="back-btn">
                    <img src={BackArrowAiDesigner} onClick={() => setActiveStep(3)} />
                </div>
                <div className="header">
                    <div className="title">Pick a style</div>
                    <div className="desc">Choose a layout that works best for your ad</div>
                </div>
                <div className="content" style={{ alignItems: 'center' }}>
                    <Carousel selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate} />
                </div>
                <Button btnsize="md"
                    type="primary"
                    style={{ alignSelf: 'flex-end' }}
                    className="primary submit"
                    onClick={nextHandler}
                    disabled={!selectedTemplate}
                >
                    Next
                </Button>
            </div>
        )






    return (
        <div className="simpleprompting-card">
            {activeStep == 1 ?
                <div className="input-box">
                    <div className="header">
                        <div className="title">Simple Prompting</div>
                        <div className="desc">Simply select/enter data to generate customized creatives instantly!</div>
                    </div>

                    <div className="steps">
                        <div className="stepOf">
                            Step 1 OF 2
                        </div>
                        <div className="step-title-container">
                            <div className="step-tile" style={{ backgroundColor: '#6B38C4' }}></div>
                            <div className="step-tile" style={{ backgroundColor: '#EAEAEC' }}></div>
                        </div>
                    </div>
                    <div className="description-container">
                        <div className="description-title">Describe Copy</div>
                        <div className="description-content">Please provide the requested details  as accurately as possible for the best customized copy</div>
                    </div>
                    <div className="input-container">
                        <div className="mode-input-container" style={{ display: 'block' }} >
                            <div className="mode-input" onClick={(e) => { e.stopPropagation(); setProductPopup(true) }}>
                                <span>{product ? Object.keys(prompts).map((item, index) => ({ key: item, label: convertSnakeCaseToTitleCase(item) })).find((item, index) => item.key === product)?.label : 'Choose your product'}</span>
                                <span><svg viewBox="64 64 896 896" focusable="false" data-icon="down" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg></span>
                            </div>
                            {productPopup &&
                                <ProductPopup
                                    product={product}
                                    options={Object.keys(prompts).map((item, index) => ({ key: item, label: convertSnakeCaseToTitleCase(item) }))}
                                    setProduct={setProduct}
                                    show={productPopup}
                                    onClickOutside={() => { setProductPopup(false) }} />
                            }
                        </div>
                        {/* 
                        <div className="mode-input-container" style={{ display: 'block' }} >
                            <div className="mode-input" onClick={(e) => { e.stopPropagation(); setMustcontainPopup(true) }}>
                                <span>{mandatoryWords.length > 0 ? mandatoryWords.join(" ,") : "Mandatory Words"}</span>
                                <span><svg viewBox="64 64 896 896" focusable="false" data-icon="down" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg></span>
                            </div>
                            {mustcontainPopup &&
                                <MustContain setMandatoryWords={setMandatoryWords} mandatoryWords={mandatoryWords} show={modePopup} onClickOutside={() => { setMustcontainPopup(false) }} />
                            }
                        </div> */}
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '5px' }}>
                            <Switch checked={questionFormat} onChange={() => { setQuestionFormat(!questionFormat) }} />
                            <div className="switch-text">Generated your ad copies in question format.</div>
                        </div>
                    </div>
                    <Button btnsize="md"
                        type="primary"
                        className="primary submit"
                        style={{ alignSelf: 'flex-end' }}
                        onClick={() => setActiveStep(2)}
                        disabled={!product}
                    >
                        Next
                    </Button>
                </div> :
                <div className="input-box">
                    <div className="header">
                        <div className="title">Simple Prompting</div>
                        <div className="desc">Simply select/enter data to generate customized creatives instantly!</div>
                    </div>
                    <div className="steps">
                        <div className="stepOf">
                            Step 2 OF 2
                        </div>
                        <div className="step-title-container">
                            <div className="step-tile" style={{ backgroundColor: '#6B38C4' }}></div>
                            <div className="step-tile" style={{ backgroundColor: '#6B38C4' }}></div>
                        </div>
                    </div>
                    <div className="description-container">
                        <div className="description-title">Describe Image</div>
                        <div className="description-content">Please choose from the provided options  as accurately as possible for the best customized image</div>
                    </div>
                    <div className="input-container">
                        {data.map((item, index) => <SideSelect product={product} key={index} data={item} sideSelectData={sideSelectData} setSideSelectData={setSideSelectData} />)}
                        <div className="mode-input-container" >
                            <div className="mode-input-title">Model Age</div>
                            <div className="mode-input" onClick={(e) => { e.stopPropagation(); setModePopup(true) }}>
                                {/* <span>{ageandNumber.age.length && ageandNumber.number.length ? Object.values(ageandNumber)?.join(' ,') : "Age and Number"}</span> */}
                                {ageandNumber.number ?
                                    <span>

                                        {ageandNumber.number ? ageandNumber.number : ''}
                                    </span>
                                    :
                                    <span>{"Age and Number"}</span>

                                }
                                <span><svg viewBox="64 64 896 896" focusable="false" data-icon="down" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg></span>
                            </div>
                            {modePopup && sideSelectData["Model Gender"] != 'Multiple' &&
                                <ModePopup options={ageOptions || []} setAgeandNumber={setAgeandNumber} ageandNumber={ageandNumber} show={modePopup} onClickOutside={() => { setModePopup(false) }} />
                            }
                        </div>
                        <div className="person-scene-desc">
                            <div className="person-scene-desc-title">Person and Scene Description</div>
                            <div className="person-scene-desc-input">
                                <div className="select-a-present" onClick={() => setPromptPopup(true)}>{promptValue?.label?.length ? promptValue.label : "Select a preset"}</div>
                            </div>
                        </div>
                    </div>
                    <Button btnsize="md"
                        type="primary"
                        className="primary submit"
                        style={{ alignSelf: 'flex-end' }}
                        disabled={promptValue?.label?.length ? false : true}
                        onClick={handleGenerate}
                    >
                        Generate
                    </Button>
                </div>}
            <div className="image-box" style={{ backgroundImage: `url(${AiDesignerImageBoxBackground})` }}>
                <img src={SimplePromptingImage} />
            </div>
            {open && <ErrorDialog open={open} setOpen={setOpen} />}
            {promptPopup && <PromptPopup onClickOutside={() => setPromptPopup(false)} setPromptPopup={setPromptPopup} presetOptions={presetOptions} setPreset={setPromptValue} />}
            {PresetPopup && <Preset setPresetPopup={setPresetPopup} preset={preset} setPreset={setPreset} />}
        </div>)
}



export default SimplePromptingCard;


const PromptPopup = ({ onClickOutside, setPromptPopup, presetOptions, setPreset }) => {
    const ref = useRef<any>(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside && onClickOutside();
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [onClickOutside]);

    if (presetOptions)

        return (
            <div className="prompt-popup">
                {presetOptions.map((item, index) => <div className="prompt-item" key={index} onClick={() => { setPreset(item); setPromptPopup(false) }}>{item.label}</div>)}
            </div>
        )

    return null;
}

const options = [{ label: 'Education Loan', key: 'EDUCATION_LOAN' }, { label: 'Home Loan', key: 'HOME_LOAN' }]



function convertSnakeCaseToTitleCase(str) {
    const words = str.split('_');
    const titleCaseStr = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    return titleCaseStr;
}




const mock_data = {
    "request": {
        "chatGpt": {
            "status": "COMPLETED",
            "initiatedAt": "2024-02-26T12:46:54.242Z",
            "completedAt": "2024-02-26T12:47:18.449Z"
        },
        "tnl": {
            "status": "PROCESSING",
            "initiatedAt": "2024-02-26T12:46:54.242Z"
        },
        "_id": "65dc883e6ccd4aa20a3580a0",
        "userId": "5fa91cef803fe80007e484cb",
        "workspaceId": "60069086999e4000099dcfaa",
        "status": "COMPLETED",
        "type": "SIMPLE_PROMPTING",
        "request": {
            "input_text": "A young indian woman in her mid 30's taking her new home. She stands full of excitement and joy of taking her new home possession. In a casual attire in shades of blue, we see a good shot of her looking into the camera. Behind her we can see his newly build ultra modern home and garden at distance. Nikon D850, Nikkor 24-70mm, f/2.8, ISO 800 ",
            "user_id": "5fa91cef803fe80007e484cb",
            "workspace_id": "60069086999e4000099dcfaa",
            "product_type": "HOME_LOAN",
            "camera_settings": "Canon EOS 5D Mark IV, Canon EF 24-70mm f/2.8L II USM lens, f/1.2, ISO 4000, capturing a sense of joy and celebration --ar 16:9",
            "mandatory_words": [],
            "question_format": false
        },
        "isDeleted": false,
        "createdAt": "2024-02-26T12:46:54.236Z",
        "updatedAt": "2024-02-26T12:48:00.425Z",
        "__v": 0,
        "response": {
            "status": "success",
            "requestId": "59174a01-e2ac-4a7b-888b-7f9a7d4fd17e"
        },
        "metadata": [
            {
                "Primary text": "Unlock Your Dream Home",
                "Sub-text": "#InstantHomeLoan",
                "CTA": "Check your loan",
                "Sub-CTA": "In just 2 mins"
            },
            {
                "Primary text": "Your Happiness Starts Here",
                "Sub-text": "#InstantHomeLoan",
                "CTA": "Get your loan amount",
                "Sub-CTA": "Today"
            },
            {
                "Primary text": "Turn Your Dream into Reality",
                "Sub-text": "#InstantHomeLoan",
                "CTA": "Find out your loan amount",
                "Sub-CTA": "Instantly"
            },
            {
                "Primary text": "Your Future Begins with a Home",
                "Sub-text": "#InstantHomeLoan",
                "CTA": "Get your loan",
                "Sub-CTA": "In just 2 mins"
            },
            {
                "Primary text": "Welcome to Your New Home",
                "Sub-text": "#InstantHomeLoan",
                "CTA": "Check your loan offer",
                "Sub-CTA": "Today"
            },
            {
                "Primary text": "Your Dream Home is a Click Away",
                "Sub-text": "#InstantHomeLoan",
                "CTA": "Get your loan now",
                "Sub-CTA": "Offer ends on xx xxx"
            },
            {
                "Primary text": "Open the Door to Your Dream",
                "Sub-text": "#InstantHomeLoan",
                "CTA": "Calculate loan amount",
                "Sub-CTA": "In just 2 mins"
            },
            {
                "Primary text": "Make Your Dream Home Yours",
                "Sub-text": "#InstantHomeLoan",
                "CTA": "Know your loan amount",
                "Sub-CTA": "Today"
            },
            {
                "Primary text": "Your Home, Your Happiness",
                "Sub-text": "#InstantHomeLoan",
                "CTA": "Get your loan",
                "Sub-CTA": "Instantly"
            },
            {
                "Primary text": "Step into Your Future Home",
                "Sub-text": "#InstantHomeLoan",
                "CTA": "Check your eligibility",
                "Sub-CTA": "In just 2 mins"
            }
        ],
        "imageResponse": {
            "imageUrls": [
                "https://cocreate-text-to-image-stage.s3.ap-south-1.amazonaws.com/text-to-image/59174a01-e2ac-4a7b-888b-7f9a7d4fd17e-0.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=ASIA5DTTI3E55QIYNISE%2F20240226%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240226T124807Z&X-Amz-Expires=3600&X-Amz-Security-Token=IQoJb3JpZ2luX2VjENX%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDFIoL5YMMwDTqBoRmxVcew86gc4d9kyx4VQXkUgscM%2BgIhAKC8F%2FP1SaW2WV0XU79g5jYE0H9Uf3MfMxXUi%2B5QAO6AKpgFCL7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQARoMOTAxMTExMTQyNzE1Igyjn60E%2B95MnVos54wq7AQKhkNNhXkFc1KMGs2I8NKa%2FwgWWMOVgwjPZ3CXV%2B%2BV1OETvvRTc%2B4jBRy7qM2TQx2pnEc2yv%2B6chCX9%2Bh0jD0UbRm5rg0%2BKfPXbZQh5vlIF6yHtP9xtEid6w6W%2FFuwxwcQO%2BgE2VaiNYJpD%2BiIyAxknypt5%2FXesth%2Bbd56dG5giD2mAKrz3ZKWKBKeeo7vpFxZLdRkScZaqxpp2zjcmMr4R%2F%2FeJPOeruQouHjSc90kXIWHTLCZQPNwVbeHQ5o6MLD7ykQB0xmVWJ7asxEOUinuOyKMYSObLIy3%2BAPsH3z36qEeSNSdVZIRfBhj7%2BgdjzBgq3wwDFOHQHbrxDC837KSIwRvSNV%2B6XamJgzC%2F%2FDD88uL02dCsDx09KtRbrkldii345fSp1ZdVlSLKojdrZgjvPepr98RczoQF0%2B5suZG%2Fk5P8Qd56LE3XlW3hpUyz3jKK1zoJCz0bcv%2BSQK8t37WpYflXnhJNtl8QsrhRNcsjhOgxvhsLtx8JPHUZ9VphRXrJlLV60%2F10xRWznoZQ3nxH%2FrHBYGq7qsnvRDqoNKZ3W1%2BbWAIaL6kQUA7leMD9Ge9JFbKw1ojUp7i0lp%2BcbfgUo8BF5GlVAD7kHgaTzxZS6bMfBwtGGbiAkaUKA0IFONX62seremfjBbLzoggIWrleQ7IsEfH6FC5UkFHw%2F%2Bkp7a23MiQgOFZ%2FPNg6htlaSrYAPC8TB5XugmtxXTscYJPd7O3taLOwDx3tF6lQWcZpYSZaAYy0ZjqcYb5nVN9BCj%2BNSmCSF2c7UitNqbefWUG3v61v0U3GLUkFkK7i4HllideoIZq33dZhMbIPzDekPKuBjqaAQuUaPGAGWJEb5F0PQ%2FNV5yyF%2BzVqF4qw2xsLONofQCJxNQyViL%2BR87lI1lOVKQzYVahS1sDyRNqILfP1c8kvO0LsgDtuN2MFl1pyL%2FaKAw7gfZEN2YoCiyWhVuLaYUdLBW3qxaQ5ru%2BugzUGdp4dye5qIW4nTvVCw9pIBAhZcVINiSJFdIdO0vjMj8NkAU7jRoT5dqqRZDjS1Y%3D&X-Amz-Signature=7e559ac947fc0a8dbed8c97ee7b4e7296d8efcee4319820643dbfbaa889ad217&X-Amz-SignedHeaders=host&x-id=GetObject",
                "https://cocreate-text-to-image-stage.s3.ap-south-1.amazonaws.com/text-to-image/59174a01-e2ac-4a7b-888b-7f9a7d4fd17e-1.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=ASIA5DTTI3E55QIYNISE%2F20240226%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240226T124807Z&X-Amz-Expires=3600&X-Amz-Security-Token=IQoJb3JpZ2luX2VjENX%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDFIoL5YMMwDTqBoRmxVcew86gc4d9kyx4VQXkUgscM%2BgIhAKC8F%2FP1SaW2WV0XU79g5jYE0H9Uf3MfMxXUi%2B5QAO6AKpgFCL7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQARoMOTAxMTExMTQyNzE1Igyjn60E%2B95MnVos54wq7AQKhkNNhXkFc1KMGs2I8NKa%2FwgWWMOVgwjPZ3CXV%2B%2BV1OETvvRTc%2B4jBRy7qM2TQx2pnEc2yv%2B6chCX9%2Bh0jD0UbRm5rg0%2BKfPXbZQh5vlIF6yHtP9xtEid6w6W%2FFuwxwcQO%2BgE2VaiNYJpD%2BiIyAxknypt5%2FXesth%2Bbd56dG5giD2mAKrz3ZKWKBKeeo7vpFxZLdRkScZaqxpp2zjcmMr4R%2F%2FeJPOeruQouHjSc90kXIWHTLCZQPNwVbeHQ5o6MLD7ykQB0xmVWJ7asxEOUinuOyKMYSObLIy3%2BAPsH3z36qEeSNSdVZIRfBhj7%2BgdjzBgq3wwDFOHQHbrxDC837KSIwRvSNV%2B6XamJgzC%2F%2FDD88uL02dCsDx09KtRbrkldii345fSp1ZdVlSLKojdrZgjvPepr98RczoQF0%2B5suZG%2Fk5P8Qd56LE3XlW3hpUyz3jKK1zoJCz0bcv%2BSQK8t37WpYflXnhJNtl8QsrhRNcsjhOgxvhsLtx8JPHUZ9VphRXrJlLV60%2F10xRWznoZQ3nxH%2FrHBYGq7qsnvRDqoNKZ3W1%2BbWAIaL6kQUA7leMD9Ge9JFbKw1ojUp7i0lp%2BcbfgUo8BF5GlVAD7kHgaTzxZS6bMfBwtGGbiAkaUKA0IFONX62seremfjBbLzoggIWrleQ7IsEfH6FC5UkFHw%2F%2Bkp7a23MiQgOFZ%2FPNg6htlaSrYAPC8TB5XugmtxXTscYJPd7O3taLOwDx3tF6lQWcZpYSZaAYy0ZjqcYb5nVN9BCj%2BNSmCSF2c7UitNqbefWUG3v61v0U3GLUkFkK7i4HllideoIZq33dZhMbIPzDekPKuBjqaAQuUaPGAGWJEb5F0PQ%2FNV5yyF%2BzVqF4qw2xsLONofQCJxNQyViL%2BR87lI1lOVKQzYVahS1sDyRNqILfP1c8kvO0LsgDtuN2MFl1pyL%2FaKAw7gfZEN2YoCiyWhVuLaYUdLBW3qxaQ5ru%2BugzUGdp4dye5qIW4nTvVCw9pIBAhZcVINiSJFdIdO0vjMj8NkAU7jRoT5dqqRZDjS1Y%3D&X-Amz-Signature=c148238c562252a1d28a653eeaf72c11f696ca1fd9e714505352c0fc7f3d9bd9&X-Amz-SignedHeaders=host&x-id=GetObject",
                "https://cocreate-text-to-image-stage.s3.ap-south-1.amazonaws.com/text-to-image/59174a01-e2ac-4a7b-888b-7f9a7d4fd17e-2.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=ASIA5DTTI3E55QIYNISE%2F20240226%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240226T124807Z&X-Amz-Expires=3600&X-Amz-Security-Token=IQoJb3JpZ2luX2VjENX%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDFIoL5YMMwDTqBoRmxVcew86gc4d9kyx4VQXkUgscM%2BgIhAKC8F%2FP1SaW2WV0XU79g5jYE0H9Uf3MfMxXUi%2B5QAO6AKpgFCL7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQARoMOTAxMTExMTQyNzE1Igyjn60E%2B95MnVos54wq7AQKhkNNhXkFc1KMGs2I8NKa%2FwgWWMOVgwjPZ3CXV%2B%2BV1OETvvRTc%2B4jBRy7qM2TQx2pnEc2yv%2B6chCX9%2Bh0jD0UbRm5rg0%2BKfPXbZQh5vlIF6yHtP9xtEid6w6W%2FFuwxwcQO%2BgE2VaiNYJpD%2BiIyAxknypt5%2FXesth%2Bbd56dG5giD2mAKrz3ZKWKBKeeo7vpFxZLdRkScZaqxpp2zjcmMr4R%2F%2FeJPOeruQouHjSc90kXIWHTLCZQPNwVbeHQ5o6MLD7ykQB0xmVWJ7asxEOUinuOyKMYSObLIy3%2BAPsH3z36qEeSNSdVZIRfBhj7%2BgdjzBgq3wwDFOHQHbrxDC837KSIwRvSNV%2B6XamJgzC%2F%2FDD88uL02dCsDx09KtRbrkldii345fSp1ZdVlSLKojdrZgjvPepr98RczoQF0%2B5suZG%2Fk5P8Qd56LE3XlW3hpUyz3jKK1zoJCz0bcv%2BSQK8t37WpYflXnhJNtl8QsrhRNcsjhOgxvhsLtx8JPHUZ9VphRXrJlLV60%2F10xRWznoZQ3nxH%2FrHBYGq7qsnvRDqoNKZ3W1%2BbWAIaL6kQUA7leMD9Ge9JFbKw1ojUp7i0lp%2BcbfgUo8BF5GlVAD7kHgaTzxZS6bMfBwtGGbiAkaUKA0IFONX62seremfjBbLzoggIWrleQ7IsEfH6FC5UkFHw%2F%2Bkp7a23MiQgOFZ%2FPNg6htlaSrYAPC8TB5XugmtxXTscYJPd7O3taLOwDx3tF6lQWcZpYSZaAYy0ZjqcYb5nVN9BCj%2BNSmCSF2c7UitNqbefWUG3v61v0U3GLUkFkK7i4HllideoIZq33dZhMbIPzDekPKuBjqaAQuUaPGAGWJEb5F0PQ%2FNV5yyF%2BzVqF4qw2xsLONofQCJxNQyViL%2BR87lI1lOVKQzYVahS1sDyRNqILfP1c8kvO0LsgDtuN2MFl1pyL%2FaKAw7gfZEN2YoCiyWhVuLaYUdLBW3qxaQ5ru%2BugzUGdp4dye5qIW4nTvVCw9pIBAhZcVINiSJFdIdO0vjMj8NkAU7jRoT5dqqRZDjS1Y%3D&X-Amz-Signature=b47dfa04b3ba5fb16c46541a17925ce7e53934db02779bade8136304580a6f48&X-Amz-SignedHeaders=host&x-id=GetObject",
                "https://cocreate-text-to-image-stage.s3.ap-south-1.amazonaws.com/text-to-image/59174a01-e2ac-4a7b-888b-7f9a7d4fd17e-3.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=ASIA5DTTI3E55QIYNISE%2F20240226%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240226T124807Z&X-Amz-Expires=3600&X-Amz-Security-Token=IQoJb3JpZ2luX2VjENX%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDFIoL5YMMwDTqBoRmxVcew86gc4d9kyx4VQXkUgscM%2BgIhAKC8F%2FP1SaW2WV0XU79g5jYE0H9Uf3MfMxXUi%2B5QAO6AKpgFCL7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQARoMOTAxMTExMTQyNzE1Igyjn60E%2B95MnVos54wq7AQKhkNNhXkFc1KMGs2I8NKa%2FwgWWMOVgwjPZ3CXV%2B%2BV1OETvvRTc%2B4jBRy7qM2TQx2pnEc2yv%2B6chCX9%2Bh0jD0UbRm5rg0%2BKfPXbZQh5vlIF6yHtP9xtEid6w6W%2FFuwxwcQO%2BgE2VaiNYJpD%2BiIyAxknypt5%2FXesth%2Bbd56dG5giD2mAKrz3ZKWKBKeeo7vpFxZLdRkScZaqxpp2zjcmMr4R%2F%2FeJPOeruQouHjSc90kXIWHTLCZQPNwVbeHQ5o6MLD7ykQB0xmVWJ7asxEOUinuOyKMYSObLIy3%2BAPsH3z36qEeSNSdVZIRfBhj7%2BgdjzBgq3wwDFOHQHbrxDC837KSIwRvSNV%2B6XamJgzC%2F%2FDD88uL02dCsDx09KtRbrkldii345fSp1ZdVlSLKojdrZgjvPepr98RczoQF0%2B5suZG%2Fk5P8Qd56LE3XlW3hpUyz3jKK1zoJCz0bcv%2BSQK8t37WpYflXnhJNtl8QsrhRNcsjhOgxvhsLtx8JPHUZ9VphRXrJlLV60%2F10xRWznoZQ3nxH%2FrHBYGq7qsnvRDqoNKZ3W1%2BbWAIaL6kQUA7leMD9Ge9JFbKw1ojUp7i0lp%2BcbfgUo8BF5GlVAD7kHgaTzxZS6bMfBwtGGbiAkaUKA0IFONX62seremfjBbLzoggIWrleQ7IsEfH6FC5UkFHw%2F%2Bkp7a23MiQgOFZ%2FPNg6htlaSrYAPC8TB5XugmtxXTscYJPd7O3taLOwDx3tF6lQWcZpYSZaAYy0ZjqcYb5nVN9BCj%2BNSmCSF2c7UitNqbefWUG3v61v0U3GLUkFkK7i4HllideoIZq33dZhMbIPzDekPKuBjqaAQuUaPGAGWJEb5F0PQ%2FNV5yyF%2BzVqF4qw2xsLONofQCJxNQyViL%2BR87lI1lOVKQzYVahS1sDyRNqILfP1c8kvO0LsgDtuN2MFl1pyL%2FaKAw7gfZEN2YoCiyWhVuLaYUdLBW3qxaQ5ru%2BugzUGdp4dye5qIW4nTvVCw9pIBAhZcVINiSJFdIdO0vjMj8NkAU7jRoT5dqqRZDjS1Y%3D&X-Amz-Signature=22bb6aef8293a76ea0d570ed017b4db05d37d3e84042f0865fe008c8562ac526&X-Amz-SignedHeaders=host&x-id=GetObject"
            ],
            "createdAt": "2024-02-26T12:48:00.418Z"
        }
    }
}