import React,{useState,useEffect} from 'react';
import BrokenImage from '../../assets/img/BrokenImage.png';
import { imageLoaderParameter } from '../../utilities/common-function';
import './ImageLoader.scss';
import loader5 from '../../assets/img/loader5.gif';
import { getSvg1 } from '../Main/FabricLoaders/objectGetters';

const Img:any = ({ src, alt, style, className, contextRef, ...props }:any) => {  
  const [loading,setLoading] = useState(true);

  useEffect(()=>{
    setLoading(true)
  }, [src])
  return <>
        <img 
          className={`${className||""} image-loader ${loading ? 'image-loader-display': 'image-loader-hide'}`} 
          src={loader5}
          style={{objectFit:'scale-down', objectPosition:'center', width: 'auto', margin: '0 auto'}}
          alt=""
        />  
        <img 
          className={`${className||""} image-stage-loaded ${loading ? 'image-loading': 'image-loaded'}`} 
          ref={contextRef} 
          src={src&&!src.includes("gif")?imageLoaderParameter(src,40):src}
          style={{objectFit: 'cover', objectPosition:'top center',...style||{}}}
          alt={alt}
          onLoad={() => {
            setLoading(false)
          }}
          onError={(e:any)=>{
            const target = e.target;
            if (src.includes('.svg')) {
              getSvg1(src).then(({ img }: any) => {
                const string = new XMLSerializer().serializeToString(img as any);
                const blob = new Blob([string], { type: 'image/svg+xml' });
                target.src = URL.createObjectURL(blob);
                setLoading(false)
              })
            } else {
              target.src = BrokenImage;
              setLoading(false)
            }
            target.onerror = null;
            target.style.objectFit='scale-down';
            target.style.objectPosition='center center';
          }}
          {...props}
        />
     </>
}

export default Img;