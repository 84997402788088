// Component Used in
// 1. Brand library files and folder
// 2. Brand Template
// 3. Brand Colors
// 4. Design files and folder
// 5. HyperPersonalize files and folder
// 6. View design files
// 7. View HyperPersonalize files
import React, { FC, useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  ExportState,
  FolderState,
  HyperProjectFoldersState,
  LibraryState,
} from "../../../store/types";

import { Button, Checkbox, Tooltip } from "pyxis-ui-kit";
import {
  DeleteOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
  Loading3QuartersOutlined,
} from "@ant-design/icons";
import {
  deleteHyperRenderedImages,
  setSelectedColors,
  setSelectedFolders,
  setSelectedHpProjects,
  setSelectedMyTemplates,
  setSelectedProject,
  deleteVariant,
  setProject,
  setLoader,
  uploadVleProof,
} from "../../../store/actions";
import "./index.scss";
import { DeleteAlert } from "../DeleteAlert";
import { DropDownOption } from "../DropDownOption";
import { openToast } from "../../../Toasts";
import { useHistory, useParams } from "react-router-dom";
import {
  downloadFile,
  getIsTypeEditable,
} from "../../../utilities/common-function";
import {
  exportMultiple,
  setExportedProjects,
} from "../../../store/actions/export";
import { IMAGE_SUPPORTED } from "../../../utilities/contants";
import UploadProofs from "../UploadProofs";
import axios from "axios";
import { get, set } from "lodash";
import httpService from "../../../HttpService/HttpService";
//import { vleProofWatcher } from "../../../store/sagas";
import moment from "moment";
import { store } from "../../../store/store";
interface TopFilterInterface {
  componentCaller: string;
  projectType: string;
  isSearched?: any;
  title?: string;
  titleRef?: any;
  getActiveFolder?: Function;
  className?: string;
  drawerVisible: string;
  setDrawerVisible: Function;
  bannerData?: any;
  renderedCount?: number;
  renderData?: any;
  isFiltered?: boolean;
  canManage?: boolean;
  hasFailedProjects?: boolean;
  isMultipleDownloadDisable?: boolean;
  isPublicLink?: boolean;
  isBothFormatSelected?: boolean;
  openUploadVleProofs?: boolean;
}
export const TopFilter: FC<TopFilterInterface> = ({
  componentCaller,
  projectType,
  titleRef,
  className,
  drawerVisible,
  setDrawerVisible,
  bannerData,
  renderedCount,
  renderData,
  isFiltered,
  canManage,
  hasFailedProjects,
  isMultipleDownloadDisable,
  isPublicLink,
  isBothFormatSelected,
  openUploadVleProofs = false,
}) => {
  console.log(
    "openUploadVleProofs>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ",
    openUploadVleProofs
  );
  const [isDownload, setIsDownload] = useState(false);
  const [isMove, setIsMove] = useState(false);
  const [openUploadProofPopUp, setOpenUploadProofPopUp] = useState(false);
  const [dropDownStep, setDropDownStep] = useState(0);
  const history = useHistory();
  const params: any = useParams();
  const [showProofSubmission, setShowProofSubmission] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const {
    location: { search },
  } = history;
  const searchParam = new URLSearchParams(search);
  const banner = searchParam.get("banner");
  // Initialization
  const folderstate = useSelector(
    (state: { folders: FolderState }) => state.folders
  );
  const librarystate = useSelector(
    (state: { library: LibraryState }) => state.library
  );
  const hyperState = useSelector(
    (state: { hyperProject: HyperProjectFoldersState }) => state.hyperProject
  );
  const { colors, selectedColors, templates, selectedTemplates } = librarystate;
  const {
    folders,
    activeFolderId,
    selectedFolders,
    banners,
    selectedProjects,
  } = folderstate;
  const { selectedHyperProjects } = hyperState;
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [proofSubmitted, setProofSubmitted] = useState(false);
  const [uploadExpired, setUploadExpired] = useState(false);
  const [expirationTime, setExpirationTime] = useState(null);
  const [proofSubmittedBtn, setProofSubmittedBtn] = useState(false);
  // Function
  const filterFolders = useCallback(
    (id: number) => {
      return folders.filter((obj) => obj.parent === id);
    },
    [folders]
  );

  isPublicLink = history.location.pathname.startsWith("/cocreate/public");

  const [isMobile, setIsMobile] = useState(false);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  // Check selection
  const isAllFoldersSelected = !!(
    selectedFolders.length === filterFolders(activeFolderId).length &&
    selectedFolders.length !== 0
  );
  const isAllProjectsSelected = !!(
    selectedProjects.length === banners.length && selectedProjects.length !== 0
  );
  const isAllProjectViewSelected =
    bannerData &&
    (!!(
      selectedHyperProjects.length === bannerData.banners.length &&
      selectedHyperProjects.length !== 0
    ) ||
      !!(
        selectedProjects.length === bannerData.banners.length &&
        selectedProjects.length !== 0
      ));
  const isAllColorsSelected = !!(
    selectedColors.length === colors.length && selectedColors.length !== 0
  );
  const isAllTemplatesSelected = !!(
    selectedTemplates.length === templates.length &&
    selectedTemplates.length !== 0
  );
  const isAllContentSelected =
    componentCaller === "folder"
      ? filterFolders(activeFolderId).length > 0 && isAllFoldersSelected
      : componentCaller === "projectDetail"
      ? bannerData.banners.length > 0 && isAllProjectViewSelected
      : banners.length > 0 && isAllProjectsSelected;
  const isAllSelected =
    isAllContentSelected || isAllColorsSelected || isAllTemplatesSelected;
  const isPartiallySelected =
    (componentCaller === "folder"
      ? selectedFolders.length > 0
      : (componentCaller === "projectDetail" ||
          componentCaller === "project") &&
        (selectedHyperProjects.length > 0 || selectedProjects.length > 0)) ||
    selectedColors.length > 0 ||
    selectedTemplates.length > 0;
  const { exportedProjects } = useSelector(
    (state: { export: ExportState }) => state.export
  );

  // Selection
  const handleSelect: Function = useCallback(
    (actiontype: string) => {
      switch (actiontype) {
        case "selectAll": {
          if (componentCaller === "folder") {
            let filtered = folders.filter(
              (folder) => folder.parent === activeFolderId
            );
            let reduced: any = filtered.reduce((acc: any, val) => {
              return [...acc, val.id];
            }, []);
            dispatch(setSelectedFolders(reduced));
          } else if (componentCaller === "project") {
            if (projectType === "color") {
              let reduced: any = colors.reduce((acc: any, val) => {
                return [...acc, val.id];
              }, []);
              dispatch(setSelectedColors(reduced));
            } else if (projectType === "template") {
              let reduced: any = templates.reduce((acc: any, val) => {
                return [...acc, val.id];
              }, []);
              dispatch(setSelectedMyTemplates(reduced));
            } else {
              let ids: any = banners.reduce((acc: any, val) => {
                return [...acc, val.id];
              }, []);
              dispatch(setSelectedProject(ids));
            }
          } else if (componentCaller === "projectDetail") {
            let ids: any = bannerData.banners.reduce((acc: any, val: any) => {
              return [...acc, val.id];
            }, []);
            if (projectType === "project") {
              dispatch(setSelectedProject(ids));
            } else {
              dispatch(setSelectedHpProjects(ids));
            }
          }
          return;
        }
        case "None": {
          if (componentCaller === "folder") {
            dispatch(setSelectedFolders([]));
          } else if (componentCaller === "project") {
            if (projectType === "color") {
              dispatch(setSelectedColors([]));
            } else if (projectType === "template") {
              dispatch(setSelectedMyTemplates([]));
            } else {
              dispatch(setSelectedProject([]));
            }
          } else if (componentCaller === "projectDetail") {
            if (projectType === "project") {
              dispatch(setSelectedProject([]));
            } else {
              dispatch(setSelectedHpProjects([]));
            }
          }
          return;
        }
      }
    },
    [
      componentCaller,
      folders,
      dispatch,
      activeFolderId,
      projectType,
      colors,
      templates,
      banners,
      bannerData?.banners,
    ]
  );

  const handleDelete = () => {
    if (banner) {
      let id: number = parseInt(banner);
      if (projectType === "project") {
        dispatch(
          deleteVariant(
            { project_id: id, project_size: selectedProjects },
            (res: any, err: boolean) => {
              if (!err) {
                bannerData.banners = bannerData.banners.filter(
                  (b: any) => selectedProjects.indexOf(b.id) === -1
                );
                bannerData.renderedimages = bannerData.renderedimages.filter(
                  (b: any) => selectedProjects.indexOf(b.id) === -1
                );
                bannerData.rendered_count =
                  bannerData.rendered_count - selectedProjects.length;
                if (bannerData.banners.length === 1) {
                  bannerData.type = "image";
                }
                dispatch(setProject(bannerData));
                dispatch(setSelectedProject([]));
                openToast(
                  "success",
                  `Variant${selectedProjects.length > 1 ? "s" : ""} deleted`
                );
              }
            }
          )
        );
      } else {
        dispatch(
          deleteHyperRenderedImages(
            { project_id: id, ids: selectedHyperProjects },
            (res: any, err: boolean) => {
              if (!err) {
                bannerData.banners = bannerData.banners.filter(
                  (b: any) => selectedHyperProjects.indexOf(b.id) === -1
                );
                bannerData.count =
                  bannerData.count - selectedHyperProjects.length;
                dispatch(setProject(bannerData));
                dispatch(setSelectedHpProjects([]));
                openToast(
                  "success",
                  `Variant${
                    selectedHyperProjects.length > 1 ? "s" : ""
                  } deleted`
                );
              }
            }
          )
        );
      }
    }
  };
  const state = useSelector((state: any) => state);

  const getTokenOrPublic = (state: any) =>
    state.authentication.token || state.publicLink.token;
  useEffect(() => {
    getExpriationTime();
    toggleVisibility();
    return () => {
      dispatch(setSelectedFolders([]));
      dispatch(setSelectedColors([]));
      dispatch(setSelectedMyTemplates([]));
    };
  }, [dispatch]);

  const handleUploadProofs = (vle_Id: string, images: File[]) => {
    uploadVleProof({ vle_Id, images });
  };

  useEffect(() => {
    getExpriationTime();
    return () => {
      if (proofSubmitted == true && proofSubmittedBtn == true) {
        setProofSubmittedBtn(true);
      }
    };
  });

  const toggleVisibility = () => {
    let vle_ID = sessionStorage.getItem("vleId");
    if (vle_ID != null) {
      setIsVisible(!isVisible);
      console.log("isVisible:::", isVisible);
    }
  };

  const getExpriationTime = async () => {
    console.log("getting expiration time");
    let vle_ID = sessionStorage.getItem("vleId");
    if (vle_ID !== null) {
      console.log(vle_ID);

      await httpService
        .get(
          process.env.REACT_APP_DESIGN_URI,
          `/api/project/getVleData/?vle_id=${JSON.parse(vle_ID)}&url_hash=${
            params.projectLinkKey
          }`,
          getTokenOrPublic(state)
        )
        .then((res: any) => {
          if (res.data?.data?.expiration_date?.length > 0) {
            setExpirationTime(res.data.data.expiration_date);
            let currentDateTime = new Date().toLocaleString();
            let data = calculateTimeRemaining(res.data?.data?.expiration_date);
            if (data == null) {
              console.log("currentDateTime>>>> ", currentDateTime);
              setUploadExpired(true);
            }
          }
          if (res.data?.data?.submit_proof_flag) {
            setProofSubmitted(true);
            setProofSubmittedBtn(true);
          }
          console.log(res.data.data.submit_proof_flag);
        })
        .catch((err: any) => {
          console.log(err);
          setProofSubmitted(true);
        });
    }
  };

  const updateTime = async () => {
    let vle_ID = sessionStorage.getItem("vleId");

    const queryParams = `vle_id=${JSON.parse(
      vle_ID
    )}&submit_flag=false&url_hash=${params.projectLinkKey}`;
    httpService
      .put(
        process.env.REACT_APP_DESIGN_URI,
        "/api/project/updateVleData/?" + queryParams,
        getTokenOrPublic(state),
        {}
      )
      .then((response) => {
        console.log(response.data);
        setProofSubmitted(response.data.data.proofSubmit);
        setProofSubmittedBtn(response.data.data.proofSubmit);
        setExpirationTime(response.data.data.expirationDate);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSingleDownload = () => {
    let selectedProject = banners.find(
      (p: any) => p.id === selectedProjects[0]
    );

    console.log("handle single download...");
    let vle_ID = localStorage.getItem("vleId");
    if (vle_ID !== null && vle_ID !== "") {
      console.log("vle_ID", vle_ID);
      //let data = dispatch(GET_VLE_DATA_ACTION(vle_ID));
      let data = {
        vle_ID: "323617710015",
        expiration_date: null,
        submit_proof: false,
      };
      if (data !== null) {
        let proof_flag = data?.submit_proof;
        let expiration_date = data?.expiration_date;
        console.log("getPerticularVleData::", proof_flag);
        console.log("expiration_date::", expiration_date);
        if (expiration_date == null && proof_flag == false) {
          //dispatch(updateVleExpirationDateAction(vle_ID,expiration_date,proof_flag));
          let today = new Date();
          expiration_date = today.getDate() + 60;
          console.log("open side drawer box for vle proof image upload");
          // console.log("set upload proof inside if condition:::", openUploadProofPopUp);
          // <UploadProofs onSubmit={handleUploadProofs} handleClose={() => setOpenUploadProofPopUp(false)}/>
        } else if (expiration_date !== null && proof_flag == false) {
          // console.log("set upload proof inside elseif", openUploadProofPopUp);
        }
        // console.log("set upload proof outside consitions::;", openUploadProofPopUp);
      }
    }

    if (!selectedProject.rendered_file.includes("null")) {
      downloadFile(
        selectedProject?.rendered_file.split("?")[0],
        selectedProject?.title
      );
      updateTime();
      if (sessionStorage.getItem("vleId") !== null) {
        setShowProofSubmission(true);
      }
    } else {
      openToast("error", "Unable to export project!");
    }
  };

  const ismultipledownload = () => {
    if (selectedFolders.length) return true;
    else if (selectedProjects.length > 1) return true;
    return false;
  };

  const handleOriginalExport = () => {
    let selectedProject = banners.find(
      (p: any) => p.id === selectedProjects[0]
    );
    console.log("multiple===>", store.getState().roles.selectedProductId);
    const handleExport = (exportType: string, qualityVal?: number) => {
      let payload: any = {
        project_type: projectType,
        output_format: exportType,
        quality: qualityVal ? qualityVal : undefined,
        projects: selectedProjects,
        folders: selectedFolders,
      };
      let isDownloadEnable: boolean = true;

      // Multiple project or multiple hp download
      let projectDetail = selectedProjects;
      // For selected download in hp view wait until hp is loaded in backend
      // In project view or hp view wait until project or hp is loaded in backend

      if (isDownloadEnable) {
        setLoader(true);
        dispatch(
          exportMultiple(payload, (res: any, err: boolean) => {
            if (!err) {
              // after dispatch of setExportedProjects 'DownloadPopup' component will appear
              dispatch(setExportedProjects([...exportedProjects, res.data]));
              // setIsOpenMoreVisible(false)
              // setSelectedKeys([])
              setDropDownStep(0);
            } else {
              openToast("error", "Unable to export!");
            }
            setLoader(false);
          })
        );
        updateTime();
        if (sessionStorage.getItem("vleId") !== null) {
          setShowProofSubmission(true);
        }
        setShowProofSubmission(true);
      }
    };

    handleExport("png");
  };

  const showDownloadBtn = function() {
    const folderElement = document?.querySelector(".folders");
    const childElements = folderElement?.childNodes.length;
    if (componentCaller == "project" && childElements > 2) {
      return false;
    } else if (childElements == 0) {
      return true;
    }
    return true;
  };

  const toolTipText = () => {
    let text = "";
    if (calculateTimeRemaining(expirationTime) == null && !proofSubmitted) {
      text =
        "Oops! the proof submission window has been expired. Please contact Regional manager.";
    } else if (proofSubmittedBtn) {
      // text = 'You’ve already submitted the proof.Please wait till we analyse it.'
    }
    console.log("text::", text);
    return text;
  };

  return (
    <>
      {/* this extra div is for scrollTop as heading is stikcy */}
      <div className='top-header' ref={titleRef}></div>
      <div className={`heading ${className ? className : ""}`}>
        <div className='left-option'>
          {componentCaller === "projectDetail" ? (
            <span className='title noselect' ref={titleRef}>
              {projectType === "project"
                ? bannerData
                  ? bannerData.render_status === "COMPLETED" ||
                    bannerData.render_status === "NOT_STARTED"
                    ? `${bannerData?.banners?.length}/${bannerData?.banners?.length} Designs`
                    : bannerData?.banners?.length === 0
                    ? ""
                    : `${renderedCount} / ${bannerData?.banners?.length} Designs`
                  : ""
                : projectType === "hpproject" && renderData && bannerData
                ? renderData.render_status === "COMPLETED"
                  ? `${bannerData?.count} / ${bannerData?.count} Designs ${
                      isFiltered ? " filtered" : ""
                    }`
                  : `${bannerData?.rendered_count}/${
                      bannerData?.count
                    } Designs ${isFiltered ? " filtered" : ""}`
                : ""}
            </span>
          ) : (
            <span
              className={
                projectType === "template" ? "title templateTitle" : "title"
              }
            >
              {componentCaller === "folder"
                ? `Folders ${
                    selectedFolders.length > 0
                      ? "(" + selectedFolders.length + ")"
                      : ""
                  }`
                : projectType === "color"
                ? "Brand colors"
                : projectType === "template"
                ? "Templates"
                : projectType === "file"
                ? "Files"
                : (projectType === "hpproject" || projectType === "project") &&
                  "Design Projects"}
            </span>
          )}
          {projectType === "template" && (
            <Tooltip
              placement='right'
              title="Choose 'Add to templates' in the designs page to save the project here"
              content={
                <span className='info-icon'>
                  <InfoCircleOutlined className='info' />
                </span>
              }
            ></Tooltip>
          )}
          {componentCaller === "folder" ||
          (componentCaller === "projectDetail" && projectType === "hpproject")
            ? false
            : canManage &&
              !isPublicLink && (
                <>
                  <Checkbox
                    className='select-checkbox'
                    onChange={(e: any) => {
                      e.stopPropagation();
                      if (isAllSelected) {
                        handleSelect("None");
                      } else if (isPartiallySelected) {
                        handleSelect("selectAll");
                      } else {
                        handleSelect("selectAll");
                      }
                    }}
                    checked={isAllSelected}
                  />
                  <span className='select-all'>
                    {isAllSelected ? "Deselect all " : "Select all "}
                    {componentCaller === "project" ||
                    componentCaller === "projectDetail"
                      ? projectType === "color"
                        ? selectedColors.length > 0 &&
                          "(" + selectedColors.length + ")"
                        : projectType === "template"
                        ? selectedTemplates.length > 0 &&
                          "(" + selectedTemplates.length + ")"
                        : selectedProjects.length > 0 &&
                          "(" + selectedProjects.length + ")"
                      : ""}
                  </span>
                </>
              )}
        </div>
        {console.log(
          "open upload drawer:: setOpenUploadProofPopUp",
          openUploadProofPopUp
        )}

        {!openUploadProofPopUp && showDownloadBtn() && !isMobile && (
          <div className='right-option'>
            {!ismultipledownload() && isPublicLink ? (
              <>
                <Button
                  btnsize='md'
                  type='default'
                  className='download-selected'
                  onClick={handleSingleDownload}
                  disabled={
                    selectedFolders.length === 0 &&
                    selectedProjects.length === 0 &&
                    selectedColors.length === 0 &&
                    selectedTemplates.length === 0
                  }
                >
                  <DownloadOutlined className='download-icon' />
                  <span>Download Selected</span>
                </Button>
              </>
            ) : (
              <>
                <Button
                  btnsize='md'
                  type='default'
                  className={`download-selected`}
                  id='downloadBtn'
                  disabled={
                    selectedFolders.length === 0 &&
                    selectedProjects.length === 0 &&
                    selectedColors.length === 0 &&
                    selectedTemplates.length === 0
                  }
                  onClick={handleOriginalExport}
                >
                  {loader && (
                    <Loading3QuartersOutlined className='spin-icon' spin />
                  )}
                  <DownloadOutlined className='download-icon' />
                  <span style={{ marginLeft: "5px" }}>Download Selected</span>
                </Button>
              </>
            )}

            {isPublicLink && (
              <Tooltip
                overlayStyle={{
                  color: "black",
                  background: "white",
                }}
                placement='bottom'
                title={toolTipText()}
                content={
                  isVisible ? (
                    <Button
                      btnsize='md'
                      type={
                        calculateTimeRemaining(expirationTime) == null
                          ? "default"
                          : "danger"
                      }
                      danger={
                        calculateTimeRemaining(expirationTime) == null
                          ? true
                          : false
                      }
                      className={`proof-submission-btn ${
                        proofSubmitted && proofSubmittedBtn
                          ? "green-button"
                          : calculateTimeRemaining(expirationTime) == null
                          ? "red-button"
                          : ""
                      }`}
                      //style = {{opacity: calculateTimeRemaining(expirationTime) == null ? 0.4 : 1 }}
                      onClick={() => {
                        console.log("Upload Expired>>>>>>> ", uploadExpired);
                        if (!uploadExpired && !proofSubmitted) {
                          setOpenUploadProofPopUp(true);
                          console.log(
                            "setting upload proof pop up>>>>>>>>>> ",
                            openUploadProofPopUp
                          );
                        }
                      }}
                      disabled={!proofSubmittedBtn && proofSubmitted}
                    >
                      <span className=''>
                        {proofSubmitted && proofSubmittedBtn
                          ? "Proof Submission Completed"
                          : "Submit Proof Here"}
                      </span>
                    </Button>
                  ) : (
                    ""
                  )
                }
              ></Tooltip>
            )}

            {projectType !== "color" &&
              projectType !== "template" &&
              componentCaller !== "projectDetail" &&
              (componentCaller === "folder" || componentCaller === "project"
                ? canManage
                : true) && (
                <DropDownOption
                  project={
                    componentCaller === "project"
                      ? selectedProjects
                      : selectedFolders
                  }
                  componentCaller={componentCaller}
                  projectType={projectType}
                  isTopFilter='move'
                  isAllSelected={isAllSelected}
                  isPartiallySelected={isPartiallySelected}
                  isOpenMoreVisible={isMove}
                  setIsOpenMoreVisible={setIsMove}
                  setDropDownStep={setDropDownStep}
                  dropDownStep={1}
                />
              )}
            {canManage && !isPublicLink && (
              <Button
                btnsize='md'
                type='link'
                className='delete-selected'
                onClick={() => {
                  setDrawerVisible("TopFilterDelete");
                }}
                disabled={!(selectedProjects.length > 0)}
              >
                <span className='delete-txt'>Delete selected</span>
                <span>
                  <DeleteOutlined />
                </span>
              </Button>
            )}
          </div>
        )}

        {openUploadProofPopUp && (
          <UploadProofs
            setProofSubmitted={setProofSubmitted}
            expirationTime={expirationTime}
            onSubmit={handleUploadProofs}
            handleClose={() => {
              setOpenUploadProofPopUp(false);
            }}
          />
        )}
        {drawerVisible === "TopFilterDelete" && (
          <DeleteAlert
            title={
              "Delete selected " +
              (componentCaller === "folder"
                ? "folder"
                : projectType === "hpproject"
                ? `creative automation project${
                    selectedProjects.length > 1 ? "s" : ""
                  }`
                : `${projectType}${selectedColors.length > 1 ? "s" : ""}`) +
              "?"
            }
            subTitle={
              "Are you sure you want to delete selected " +
              (componentCaller === "folder"
                ? "folder"
                : projectType === "hpproject"
                ? `creative automation project${
                    selectedProjects.length > 1 ? "s" : ""
                  }`
                : `${projectType}${selectedColors.length > 1 ? "s" : ""}`) +
              "?"
            }
            isDeleteDrawerVisible={drawerVisible === "TopFilterDelete"}
            componentCaller={componentCaller}
            projectType={projectType}
            setDrawerVisible={setDrawerVisible}
            currentProject={
              componentCaller === "projectDetail" ? bannerData : undefined
            }
          />
        )}
      </div>
      {!openUploadProofPopUp && showDownloadBtn() && (
        <div className='mobile-btn-container'>
          <div className='right-option-mobile'>
            {!ismultipledownload() && isPublicLink ? (
              <>
                <Button
                  btnsize='md'
                  type='default'
                  className='download-selected mobile-btn'
                  onClick={handleSingleDownload}
                  disabled={
                    selectedFolders.length === 0 &&
                    selectedProjects.length === 0 &&
                    selectedColors.length === 0 &&
                    selectedTemplates.length === 0
                  }
                >
                  <DownloadOutlined className='download-icon' />
                  <span style={{ marginLeft: "5px" }}>Download Selected</span>
                </Button>
              </>
            ) : (
              <>
                <Button
                  btnsize='md'
                  type='default'
                  className={`download-selected mobile-btn`}
                  id='downloadBtn'
                  disabled={
                    selectedFolders.length === 0 &&
                    selectedProjects.length === 0 &&
                    selectedColors.length === 0 &&
                    selectedTemplates.length === 0
                  }
                  onClick={handleOriginalExport}
                >
                  {loader && (
                    <Loading3QuartersOutlined className='spin-icon' spin />
                  )}
                  <DownloadOutlined className='download-icon' />
                  <span style={{ marginLeft: "5px" }}>Download Selected</span>
                </Button>
              </>
            )}

            {isVisible && (
              <Tooltip
                overlayStyle={{
                  color: "black",
                  background: "white",
                }}
                placement='bottom'
                title={toolTipText()}
                content={
                  <Button
                    btnsize='md'
                    type={
                      calculateTimeRemaining(expirationTime) == null
                        ? "default"
                        : "danger"
                    }
                    danger={
                      calculateTimeRemaining(expirationTime) == null
                        ? true
                        : false
                    }
                    className={`proof-submission-btn mobile-btn ${
                      proofSubmitted && proofSubmittedBtn
                        ? "green-button"
                        : calculateTimeRemaining(expirationTime) == null
                        ? "red-button"
                        : ""
                    }`}
                    // style = {{opacity: calculateTimeRemaining(expirationTime) == null ? 0.4 : 1  }}
                    style={{
                      background:
                        proofSubmitted && proofSubmittedBtn
                          ? "#39E268"
                          : "#FF687A",
                      border:
                        proofSubmitted && proofSubmittedBtn
                          ? "#39E268"
                          : "#FF687A",
                      borderRadius: "8px",
                      opacity: proofSubmitted && !proofSubmittedBtn ? 0.4 : 1,
                    }}
                    onClick={() => {
                      console.log("Upload Expired>>>>>>> ", uploadExpired);
                      if (!uploadExpired && !proofSubmitted) {
                        setOpenUploadProofPopUp(true);
                        console.log(
                          "setting upload proof pop up>>>>>>>>>> ",
                          openUploadProofPopUp
                        );
                      }
                    }}
                    disabled={!proofSubmittedBtn && proofSubmitted}
                  >
                    <span className=''>
                      {proofSubmitted && proofSubmittedBtn
                        ? "Proof Submission Completed"
                        : "Submit Proof Here"}
                    </span>
                  </Button>
                }
              ></Tooltip>
            )}

            {projectType !== "color" &&
              projectType !== "template" &&
              componentCaller !== "projectDetail" &&
              (componentCaller === "folder" || componentCaller === "project"
                ? canManage
                : true) && (
                <DropDownOption
                  project={
                    componentCaller === "project"
                      ? selectedProjects
                      : selectedFolders
                  }
                  componentCaller={componentCaller}
                  projectType={projectType}
                  isTopFilter='move'
                  isAllSelected={isAllSelected}
                  isPartiallySelected={isPartiallySelected}
                  isOpenMoreVisible={isMove}
                  setIsOpenMoreVisible={setIsMove}
                  setDropDownStep={setDropDownStep}
                  dropDownStep={1}
                />
              )}
            {canManage && !isPublicLink && (
              <Button
                btnsize='md'
                type='link'
                className='delete-selected'
                onClick={() => {
                  setDrawerVisible("TopFilterDelete");
                }}
                disabled={!(selectedProjects.length > 0)}
              >
                <span className='delete-txt'>Delete selected</span>
                <span>
                  <DeleteOutlined />
                </span>
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

function calculateTimeRemaining(expirationDate) {
  var expirationMoment = moment(expirationDate);
  var currentMoment = moment();

  if (expirationMoment.isBefore(currentMoment)) {
    return null;
  }

  var duration = moment.duration(expirationMoment.diff(currentMoment));
  var days = Math.floor(duration.asDays());
  var hours = Math.floor(duration.asHours() % 24);

  var hoursFormatted = hours.toString().padStart(2, "0");

  var timeRemaining = days + " days : " + hoursFormatted + " hours";
  return timeRemaining;
}
