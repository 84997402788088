import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AuthState } from "../../../../store/types";
import { store } from "../../../../store/store";
export const usePollingCustomHook = (index, handleUpdate, data) => {
    const [apiData, setApiData] = useState(data);
    const token = useSelector(
        (state: { authentication: AuthState }) => state.authentication.token
    );
    const fetchDataFromApis = async (apiUrl) => {
        const responses = await fetch(apiUrl, {
            method: "GET",
            headers: { token },
        }).then((response) => response.json());
        return responses;
    };

    useEffect(() => {

        let isMounted = true; // Flag to track if the component is mounted
        if (apiData.projects.length > 1) return;
        const fetchData = async () => {
            try {
                const response = await fetchDataFromApis(
                    `${process.env.REACT_APP_DESIGN_URI}/api/export/original?export_id=${apiData.id
                    }&product_id=${store.getState().roles.selectedProductId}`
                );
                console.log("responsesss", response);

                if (!isMounted) {
                    return; // Do nothing if the component is unmounted
                }
                const { error, data } = response
                setApiData({ error, ...data?.results[0] });

                // Check if all responses have status COMPLETED

                if (data?.results[0]?.status === "COMPLETED" && data?.results[0]?.progress === 100) {
                    handleUpdate(index, { error, ...data?.results[0] });
                    return;
                }
                setTimeout(fetchData, 1000);

                // Call fetchData recursively after 2 seconds
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();

        // Cleanup function
        return () => {
            isMounted = false; // Set flag to indicate that the component is unmounted
        };
    }, []); // Empty dependency array ensures useEffect only runs once
    return {
        apiData
    }
}