import { Drawer, Button, Checkbox, DatePicker, Row, Col, Input } from 'pyxis-ui-kit';
import React, { FC, useState, useRef, useEffect } from 'react';
// import deleteSvg from '../../../assets/svg/delete.svg';
import moment from 'moment';
import './index.scss';
import { openToast } from '../../../Toasts';
import { getUrlProperties } from '../../../utilities/common-function';
import { useDispatch } from 'react-redux';
import { deleteShareableLink, getShareableLink } from '../../../store/actions';
import { Spin } from 'pyxis-ui-kit';
import { LoadingOutlined } from '@ant-design/icons';

const Loader = <LoadingOutlined style={{ fontSize: 34 }} spin />;
const dateFormat = 'YYYY-MM-DD';

const CopyIcon: FC<{ id: string, handleCopy: Function }> = ({ id, handleCopy }) => {
    return <span
        className="copy-icon tooltip-primary top"
        data-tip="Copy"
        onClick={() => {
            handleCopy(id)
        }}
    >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.6 3.6L11.6 2H2L2.00001 11.6H3.6" stroke="#152370" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M14.0004 4.40039V13.3147C14.0004 14.1102 14.0004 12.9718 14.0004 14.0004H4.4004L4.40039 4.40039" stroke="#152370" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </span>
}

const PublicLinkDrawer: FC<{
    projectId: number;
    projectType: string;
    shareable_type: string;
    onSubmit: Function;
    handleClose: Function;
}> = ({ projectType, projectId, shareable_type, onSubmit, handleClose }) => {
    const [isPasswordProtected, setIsPasswordProtected] = useState(false);
    const [isAdditionalSecuarityProtected, setIsAdditionalSecuarityProtected] = useState(false);
    const [hasExpiryDate, setHasExpiryDate] = useState(false);
    const [expiryDate, setExpiryDate] = useState(moment().add(1, 'days').format(dateFormat));
    const [loader, setLoader] = useState(false);
    const [initialLoader, setInitialLoader] = useState(true);
    const [linkData, setLinkData] = useState<any>(null);
    const linkRef = useRef<any>(null);
    const passwordRef = useRef<any>(null);
    const dispatch = useDispatch();

    const handleDateDisabled = (current: any) => {
        return moment().diff(current, 'days') > 0;
    }

    const handleCopy = (id: string) => {
        let node: any = document.getElementById(id);
        if (node) {
            node.select();
            document.execCommand("copy");
        }
    }

    useEffect(() => {
        dispatch(getShareableLink({ project_id: projectId, project_type: projectType === 'hpproject' ? 'hp-project' : projectType, shareable_type }, (res: any, err: boolean) => {
            setInitialLoader(false);
            if (!err) {
                if (!(('shareable_link_exist' in res.data) && !res.data['shareable_link_exist'])) {
                    setLinkData(res.data);
                }
            } else {
                openToast('error', res.data?.message || "Unable to fetch shareable data");
            }
        }))
    }, [])

    return <Drawer
        visible={true}
        closable={false}
        className="public-link-drawer"
        destroyOnClose
        forceRender
        onClose={() => {
            handleClose()
        }}
        width={680}
        footer={!initialLoader ? <>
            <Button type="link" className="secondary" onClick={() => handleClose()} icon={<span>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 3L6 8L10 13" stroke="#7335CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </span>}>
                Cancel
            </Button>
            <Row>
                {expiryDate && linkData && !moment(expiryDate).isSameOrAfter(moment(new Date()).format(dateFormat)) && <Button type="link" danger onClick={() => {
                    dispatch(deleteShareableLink({ projectId, projectType: projectType === 'hpproject' ? 'hp-project' : projectType, shareable_type }, (res: any, err: boolean) => {
                        if (!err) {
                            setLinkData(null)
                        } else {
                            openToast('error', res.data?.message || 'Unable to remove Link');
                        }
                    }))
                }}>
                    Remove Link
                </Button>}
                <Button type="primary" className="primary"
                    loading={loader}
                    disabled={loader}
                    icon={!linkData ? <span>
                        <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 13L10 8L6 3" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span> : null}
                    onClick={() => {
                        if (!linkData) {
                            //generate link
                            setLoader(true);
                            onSubmit({ vleId_flag: isAdditionalSecuarityProtected, password_flag: isPasswordProtected, expiration_date: hasExpiryDate ? expiryDate : '' }, (res: any, err: boolean) => {
                                setLoader(false);
                                if (!err) {
                                    setLinkData(res.data);
                                } else {
                                    openToast('error', res.data?.message || "Unable to generate link")
                                }
                            });
                        } else {
                            //copy link
                            handleCopy('shareable-link');
                        }
                    }}
                >
                    {linkData ? 'Copy Link' : 'Generate Link'}
                </Button>
            </Row>
        </> : null}
    >
        {initialLoader ? <span className="initial-loader">
            <Spin indicator={Loader} />
        </span> : <>
            <h6 className="title">
                {linkData ? 'Link Generated' : 'Share designs with an external link'}
            </h6>
            <p className="description">Get a link which
                you can share with people outside your organisation.
                Anyone on the Internet with this link can view.</p>
            {linkData ? <>
                <label className="label">Link</label>
                <Input id="shareable-link" value={`${window.location.origin}#/cocreate/public/${projectType === 'hpproject' ? 'creative-automation' : 'project'}/${linkData['shareable_link_key']}`} suffix={<CopyIcon id='shareable-link' handleCopy={handleCopy} />} />
                {linkData['password'] && <>
                    <label className="label">Password</label>
                    <Input id="shareable-pass" value={linkData['password']} suffix={<CopyIcon id='shareable-pass' handleCopy={handleCopy} />} />
                </>}
                {/* <label className="label">Expiration Date</label>
                <Input value="https://hi.com" /> */}
            </> : <>
                <Checkbox checked={isPasswordProtected}
                    onChange={() => {
                        setIsPasswordProtected((val) => (!val))
                    }}
                >Password protect the link</Checkbox>
                <Checkbox checked={hasExpiryDate}
                    onChange={() => {
                        setHasExpiryDate((val) => (!val))
                    }}
                >Add expiration date</Checkbox>
                {hasExpiryDate && <DatePicker
                    placeholder="Expiry Date"
                    value={expiryDate ? moment(expiryDate) : null}
                    format={'Do MMM YYYY'}
                    disabledDate={handleDateDisabled}
                    popupStyle={{ zIndex: 2147483003 }}
                    // getPopupContainer={trigger => trigger.parentNode as HTMLElement}
                    onChange={(value) => {
                        if (!value && expiryDate) { setExpiryDate(''); }
                        else if (value) { setExpiryDate(moment(value).format(dateFormat)); }
                    }}
                />}
                <Checkbox checked={isAdditionalSecuarityProtected}
                    onChange={() => {
                        setIsAdditionalSecuarityProtected((val) => (!val))
                    }}
                >Additional layer of security </Checkbox>

            </>}
        </>}
    </Drawer>
}

export default PublicLinkDrawer;