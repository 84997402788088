import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import MyHistroyTable from './MyHistroyTable';
import httpService from '../../HttpService/HttpService';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { RolesState } from '../../store/types';
export default function HistroyModal({ isHistoryOpen, setisHistoryOpen }) {

    const classes = useStyles();
    const [deletingRowIndex, setDeletingRowIndex] = React.useState(-1);
    const [creations, setCreations] = React.useState([]);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const getTokenOrPublic = (state: any) => state.authentication.token || state.publicLink.token;
    const state = useSelector((state) => state)
    const selectedProductId = useSelector((state: { roles: RolesState }) => (state.roles.selectedProductId));

    const handleCancelDelete = () => {
        setDeletingRowIndex(-1);
        setIsDeleting(false);

    };

    useEffect(() => {
        getCreations();
    }, [])




    const handleDeleteRow = (index) => {
        setIsDeleting(true);
        setDeletingRowIndex(index);
    }


    const getCreations = async () => {
        await httpService.get(process.env.REACT_APP_TEXT_IMAGE_CREATER_BASE_URL, `/api/history?workspaceId=${selectedProductId}`, getTokenOrPublic(state)).
            then((response) => {
                console.log(response, " response")
                setCreations(response.data)

            })
    }

    const handleConfirmDelete = (creationId) => {
        console.log(creationId, " creationId")
        setIsDeleting(false);
        httpService.delete(process.env.REACT_APP_TEXT_IMAGE_CREATER_BASE_URL, `/api/prompt/${creationId}`, getTokenOrPublic(state)).
            then((response) => {
                toast.error('Deleted Successfully')
                setDeletingRowIndex(-1);
                getCreations();
                // setisMyCreationsDetails(false)
            }).catch((err) => {
                console.log(err)
            })
    };

    return (
        <Dialog
            classes={{ paper: classes.dialogContainer }}
            fullWidth maxWidth={"md"} open={isHistoryOpen} onClose={() => setisHistoryOpen(false)}>
            <div className='dialog-header'>
                <div className='dialog-title'>
                    <div className='my-creations' style={{ fontSize: "18px", color: "#18236B" }}>
                        My prompts
                    </div>
                    <div className='sub-title'>
                        {/* Select any text to add new suggestions */}
                    </div>
                </div>
                <IconButton
                    aria-label='close'
                    className='close-btn'
                    onClick={() => setisHistoryOpen(false)}
                >
                    <CloseIcon />
                </IconButton>
            </div>
            <div className='dialog-content' style={{ paddingBottom: "8rem" }}>
                <div className='table' style={{ padding: '5rem 7rem 7rem 5rem' }}>
                    <MyHistroyTable handleConfirmDelete={handleConfirmDelete}
                        handleCancelDelete={handleCancelDelete}
                        deletingRowIndex={deletingRowIndex}
                        handleDeleteRow={handleDeleteRow}
                        prompts={creations}
                        handleMyCreationDetailsDialog={(bool, item) => { }}
                    />
                </div>
            </div>
        </Dialog>
    )
}

const useStyles = makeStyles({
    table: {
        borderCollapse: 'separate',
        borderSpacing: '0',
    },
    cell: {
        border: 'none',
        padding: '8px',
        fontSize: '12px',
        height: "54px"
    },
    actionCell: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: '200px'
    },
    hoverRow: {
        '&:hover': {
            backgroundColor: '#EEEEFF',
        },
        height: '48px'
    },
    headerRow: {
        backgroundColor: '#FCFCFF',
        '& .MuiTableCell-root': {
            padding: '16px', // Adjust the padding as per your requirements
        },
    },
    deleteButton: {
        backgroundColor: '#FFF9F9',
        border: '1px solid #FFEBEB',
        borderRadius: '6px',
        color: '#F16060',
        height: '4.5rem',
    },
    cancelDeleteButton: {
        background: '#FFF9F9',
        border: '1px solid #FFEBEB',
        borderRadius: '6px',
        padding: '6px 12px',
        fontSize: '12px',
        // width: '64px',
        height: '28px',
        lineHeight: '1.43',

        color: '#F16060',
    },
    confirmDeleteButton: {
        padding: '6px 12px',
        fontSize: '12px',
        // width: '64px',
        height: '28px',
        background: '#F16060',
        border: '1px solid #FFEBEB',
        borderRadius: '6px',
        color: 'white',
        marginLeft: '2rem',
        whiteSpace: 'nowrap'
    },
    deleteIcon: {
        color: '#F16060',
        fontSize: '14px',
    },
    deletingRow: {
        background: '#FFEBEB',
    },
    dialogContainer: {
        borderRadius: '20px', // Change the border radius value as per your requirement
        zIndex: 300,
        overflow: 'hidden',
    },
});



